.category-logo {
  position: relative;
  max-width: 100%;
  z-index: -1;
}

.category-logo-container {
  display:inline-block;
  position: relative;

  background-size: 100% 100%;
  background-position: 0 500px;
  background-repeat: no-repeat;
  -webkit-transition: all 0.1s linear;
     -moz-transition: all 0.1s linear;
       -o-transition: all 0.1s linear;
          transition: all 0.1s linear;

  background-image: -moz-linear-gradient(top, rgba(46, 146, 146, 0) 0%, rgba(46, 146, 146, 100) 100%); /* FF3.6+ */
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(46, 146, 146, 100)), color-stop(100%,rgba(46, 146, 146, 0))); /* Chrome,Safari4+ */
  background-image: -webkit-linear-gradient(top, rgba(46, 146, 146, 0) 0%,rgba(46, 146, 146, 100) 100%); /* Chrome10+,Safari5.1+ */
  background-image: -o-linear-gradient(top, rgba(46, 146, 146, 0) 0%,rgba(46, 146, 146, 100) 100%); /* Opera 11.10+ */
  background-image: -ms-linear-gradient(top, rgba(46, 146, 146, 0) 0%,rgba(46, 146, 146, 100) 100%); /* IE10+ */
  background-image: linear-gradient(to bottom, rgba(46, 146, 146, 0) 0%,rgba(46, 146, 146, 100) 100%); /* W3C */
}

.category-logo-container:hover {
  background-position: 0 0;
}

.category-logo-text {
  position: absolute;
  font-family: 'Amaranth', sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  transition: .3s;
  color: #ffffff;
  text-shadow: 1px 1px 3px black;
}

.category-logo-text {
  opacity: 100;
}

@media (min-width: 768px) {
  .category-logo-text {
    opacity: 0;
    color: #FFFFFF;
    text-shadow: none;
  }
}

.category-logo-text-large {
  font-size: 1.5em;
  width:100%;
  text-align: center;
  top: 15px;
}

@media (min-width: 768px) {
  .category-logo-text-large {
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3em;
    top: auto;
    bottom: 0px;
  }
}

.category-logo-text-small {
  font-size: 1.5em;
  width:100%;
  text-align: center;
  top: 15px;
}

@media (min-width: 768px) {
  .category-logo-text-small {
    font-size: 1.5em;
    width:100%;
    text-align: center;
    top: auto;
    bottom: 15px;
  }
}

.category-logo-container:hover > .category-logo-text {
  opacity: 100;
}

