body {
  margin: 0;
  font-family: 'Amaranth', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bigNavbar {
  position: absolute;
  min-height: 200px;
  top: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  z-index: 1050;
}

.main-container {
  margin-top: 100px;
}

@media (min-width: 768px) {
  .main-container {
    margin-top: 300px;
  }
}

.dropdown {
  pointer-events: all;
}

.portfolio-link {
  padding-right: 3em !important;
  pointer-events: all;
}

.contact-link {
  padding-left: 3em !important;
  pointer-events: all;

}

.brand-logo {
  max-width: 400px;
  background-color: #212529;
  pointer-events: all;

}

.nav-item {
  font-family: 'Amaranth', sans-serif;
  letter-spacing: 3px;
  font-weight: 700;
}

.big-nav-item {
  font-size: 2em;
}

.small-nav-item {
  font-size: 1.5em;
  margin-right: 30px;
}

.nav-link:hover {
  color: #2E9292;
}

.nav-link.isActive {
  color: #2E9292;
}

.navbar-nav .nav-link.show {
  color: rgb(46, 146, 146);
}

.absoluteBar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width:100%;
  min-height: 200px;
  background-color: #212529;
  z-index: 1050;
}

.brand-logo-small {
  max-width: 100px;
  margin-right: 50px;
}

.small-navbar {
  justify-content: normal;
}

.small-navbar-logo {
  flex-grow: 1;
}

@media (min-width: 768px) {
  .small-navbar {
    justify-content: space-between;
  }

  .small-navbar-logo {
    flex-grow: 0;
  }
}